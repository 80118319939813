import { supporterTokenMetadataQuery } from "@/api/supporter";
import { useIsAssumingRole } from "@/hooks/useIsAssumingRole";
import { usePreferredCurrencyState } from "@/hooks/usePreferredCurrencyState";
import { useSupporterId } from "@/utils/roles";
import { Box, Center, Flex, Separator, Span, Text } from "@givenwell/components";
import { CSS, styled } from "@givenwell/stitches";
import { colors } from "@givenwell/theme";
import * as BaseNavigationMenu from "@radix-ui/react-navigation-menu";
import { IconCurrencyDollar, IconHelp, IconUserQuestion } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { createLink, CreateLinkProps, Link, LinkComponent } from "@tanstack/react-router";
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef, ReactNode } from "react";
import { FlexLink } from "../ui/Link";

export function NavigationMenu({ children }: { children?: ReactNode }) {
  return (
    <Root orientation="vertical">
      <List>{children}</List>
    </Root>
  );
}

const Root = styled(BaseNavigationMenu.Root, {
  width: "100%",
  height: "100%",
  "& > *": {
    width: "100%",
    height: "100%",
  },
});
const List = styled(BaseNavigationMenu.List, {
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

type NavigationMenuLinkProps = ComponentPropsWithoutRef<"a"> & {
  css?: CSS;
  icon?: ReactNode;
  activeIcon?: ReactNode;
};

export const NavigationMenuLink = createLink(
  forwardRef(function NavigationMenuLink(
    { css, children, icon, activeIcon, ...props }: CreateLinkProps & NavigationMenuLinkProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) {
    return (
      <Item css={css}>
        <BaseNavigationMenu.Link asChild>
          <StyledA {...(props as any)} ref={ref}>
            {icon && (
              <IconBox>
                <ActiveIcon>{activeIcon || icon}</ActiveIcon>
                <InactiveIcon>{icon}</InactiveIcon>
              </IconBox>
            )}
            {children}
          </StyledA>
        </BaseNavigationMenu.Link>
      </Item>
    );
  }),
);

type NavigationMenuAProps = {
  css?: CSS;
  icon?: ReactNode;
  children?: ReactNode;
  href: string;
};

export function NavigationMenuA({ css, children, icon, ...props }: NavigationMenuAProps) {
  return (
    <Item css={css}>
      <BaseNavigationMenu.Link asChild>
        <StyledA {...props}>
          {icon && <IconBox>{icon}</IconBox>}
          {children}
        </StyledA>
      </BaseNavigationMenu.Link>
    </Item>
  );
}

const Item = styled(BaseNavigationMenu.Item, {});

const linkCSS: CSS = {
  height: 40,
  display: "flex",
  alignItems: "center",
  px: (40 - 24) / 2,
  gap: (40 - 24) / 2,

  color: colors.gray700,
  fontScale: "sm",
  weight: 400,
  rounded: 8,

  "&.active": {
    weight: 500,
    bg: colors.blue100,
    color: colors.blue800,
  },

  mb: 4,
};

const StyledLink = styled(Link, linkCSS) as unknown as LinkComponent<"a">;
const StyledA = styled("a", linkCSS);

const IconBox = styled("span", {
  display: "flex",
  items: "center",
  justify: "center",
  size: 24,

  color: colors.gray500,
  ".active > &": {
    color: colors.blue800,
  },
});

const ActiveIcon = styled("span", {
  display: "none",
  "[aria-current=page] &": {
    display: "block",
  },
});
const InactiveIcon = styled("span", {
  "[aria-current=page] &": {
    display: "none",
  },
});

export function NavigationMenuHeading({ children }: { children?: ReactNode }) {
  return (
    <Item>
      <Text
        css={{
          color: "rgba(0, 0, 0, 0.5)",
          letterSpacing: "0.6px",
          fontScale: "2xs",
          weight: 500,
          textTransform: "uppercase",
          display: "flex",
          height: 40,
          pt: 4,
          px: 8,
          items: "center",
        }}
      >
        {children}
      </Text>
    </Item>
  );
}

export function NavigationMenuSeparator() {
  return (
    <Item>
      <Hr />
    </Item>
  );
}

const Hr = styled("hr", { height: 1, backgroundColor: "rgba(0, 0, 0, 0.2)", mx: 8, mt: 16, mb: 8 });

export function SupplierNavigationMenuItems() {
  const isAssuming = useIsAssumingRole("supplier");
  return (
    <>
      <Box css={{ mt: "auto" }} />

      {isAssuming && <AssumeLink />}
      <NavigationMenuA
        href="mailto:support@givenwell.com?subject=Supplier%20Support%20Request"
        icon={<IconHelp strokeWidth={1.5} />}
      >
        Help & Support
      </NavigationMenuA>
    </>
  );
}

export function SupporterNavigationMenuItems() {
  const [currency, setCurrency] = usePreferredCurrencyState();
  const supporterId = useSupporterId();
  const tokenMeta = useQuery(supporterTokenMetadataQuery(supporterId || ""));
  const isAssuming = useIsAssumingRole("supporter");
  return (
    <>
      <Box css={{ mt: "auto" }} />
      <Flex css={{ items: "center", fontScale: "sm" }}>
        <Center css={{ size: 40 }}>
          <IconCurrencyDollar color={colors.gray500} strokeWidth={1.5} />
        </Center>
        <Span css={{ color: colors.gray600, weight: 500, flex: "1 0 0px" }}>Currency</Span>
        <Select value={currency} onChange={e => setCurrency(e.target.value as "dollar" | "token")}>
          <option value="dollar">{tokenMeta.data?.paired_currency || "Dollar"}</option>
          <option value="token">Token</option>
        </Select>
      </Flex>
      <Separator css={{ mx: 8, my: 8 }} />
      {isAssuming && <AssumeLink />}
      <NavigationMenuA
        href="mailto:support@givenwell.com?subject=Employer%20Support%20Request"
        icon={<IconHelp strokeWidth={1.5} />}
      >
        Help & Support
      </NavigationMenuA>
    </>
  );
}

function AssumeLink() {
  return (
    <FlexLink
      to="/admin"
      css={{
        padding: 8,
        borderRadius: 12,
        gap: 8,
        backgroundColor: colors.orange100,
        color: colors.orange800,
        alignItems: "center",
        fontWeight: 500,
        fontSize: 14,
        flexShrink: 100,
      }}
    >
      <IconUserQuestion color={colors.orange600} style={{ flexShrink: 0 }} strokeWidth={1.5} />
      <Span css={{ lineClamp: 1 }}>Stop assuming role</Span>
    </FlexLink>
  );
}

const Select = styled("select", {});
